*{
    font-family: 'Open Sans', sans-serif;
}
a{
    text-decoration: none;
}
.calvera_btn{
    padding: 10px 25px;
    color: #000000;
    background-color: #FF914D;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}
.calvera_btn:focus{
    outline: none;
}
.componentWrap{
    position: relative;
    min-height: 100vh;
}
.RestaurantCardWrap{
    max-height: calc(100vh - 79px);
    padding: 0 20px;
    overflow: scroll;
}
.listWrap{
    max-height: calc(100vh - 129px);
    padding: 0 20px;
    overflow: scroll;
}
.listWrap h4{
    font-weight: bold;
    font-size: 18px;
}
.inpWrap{
    display: flex;
    justify-content: center;
    margin: 2px 20px 15px;
}
.inpWrap .inpBorder{
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: flex;
}
.inpFocused{
    box-shadow: 0 0 2px 2px rgba(255,255,255,1);
}
.inpModalWrap{
    margin: 10px 0 30px;
}
.inpModalBorder{
    width: 100%;
    border-bottom: 2px solid #0EB843;
    border-radius: 5px;
    color: #000000;
    display: flex;
    background-color: #FFFFFF;

}
.inpModalBorder input{
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 15px 7px 5px;

}
.mapIconWrap{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inpWrap input{
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 15px 7px 5px;
    color: rgba(255, 255, 255, 1);
}
.inpWrap input::placeholder{
    color: rgba(255, 255, 255, 0.8);
}
.inpWrap input:focus, .inpModalBorder input{
    outline: none;
}

.listItemWrap{
    width: 100%;
    margin-bottom: 15px;
    background-color: #E5E5E5;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    display: flex;
}
.listItemWrap a{
    width: 83%;
    display: flex;
    color: #000000;
}
.infoIconWrap{
    font-size: 27px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
}
.phoneWrap{
    padding-top: 10px;
    font-size: 27px;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.phoneWrap a{
    display: flex;
    justify-content: center;
}
.mainTextWrap{
    width: 100%;
}
.itemTime{
    margin: 10px 0;
}
.itemTime span{
    padding-right: 10px;
    width: 70%;
    font-weight: bold;
}
.itemName{
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin: 7px 0;
    line-height: 21px;
}
.itemAddress{
    margin: 0 0 3px 0 ;
}
.itemDistance{
    font-weight: 300;
}
.cardItem{
    width: 100%;
    margin-bottom: 20px;
    background-color: #E5E5E5;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
.photoGallery{
    text-align: center;
    padding: 20px 20px 0 20px;
}
.restaurantName{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 20px 0 20px;
}
.restaurantDescription{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 20px 0 20px;
}
.restaurantTime{
    font-size: 16px;
    line-height: 25px;
    padding: 10px 20px 0;
}
.restaurantTime span{
    margin-right: 10px;
    font-weight: bold;
    color: #0BA33B;
}
.restaurantAddress{
    font-size: 18px;
}
.restaurantContactWrap{
    padding: 20px;
    display: flex;
}
.contacts{
    width: 80%;
}
.phones a{
    margin-right: 5px;
    color: #0F95BD;
}
.phones div{
    margin-top: 5px;
}
.social{
    width: 20%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: start;
}
.photoGallery{
    height: 200px;
}
.Carousel .Indicators{
    position: absolute;
    bottom: 10px;
}
.modal-content{
    background: #F2F2F2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.addingRestModalWrapper .modal-dialog{
    margin: 15vh 5vw 0!important;
}
.addingRestModalWrapper label{
    margin-bottom: 0;
}
.locationModalWrapper .modal-dialog{
    width: 70vw!important;
    margin: 25vh auto 0!important;
}
.feedbackModalWrapper .modal-dialog{
    width: 70vw!important;
    margin: 10vh auto 0!important;
}
.slider-control-centerleft, .slider-control-centerright{;
    display: none;
}
.slider-control-bottomcenter ul{
    top: 0!important;
}
.dataTables_length, .listWrap thead {
    display: none;
}
.dataTables_info{
    text-align: center;
}
.pagination{
    justify-content: center;
}
.listWrap .table td{
    border: none;
    padding: 0;
}
.listWrap .table{
    margin-bottom: 0;
}
.dataTables_info{
    color: rgba(255, 255, 255, 1);
}
.logoStar{
    text-align: center;
    margin: 20px;
}
.star-container{
    padding: 4px!important;
}
.star-container svg{
    width: 27px!important;
}
.starRating{
    display: flex;
    justify-content: center;
}
.categories{
    display: flex;
    flex-direction: column;
}
.categories input[type="checkbox"] {
    display: none;
    /*position: absolute;*/
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
    /*appearance: none;*/
}
.categories label {
    padding-left: 20px;
}
.categories label::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-left: -10px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.categories input[type="checkbox"]:checked + label::before {
    content: '';
    background-size: 100%;
    background-image: url(./assets/check.svg);
    background-repeat: no-repeat;
}
.content_wrap{
    margin-bottom: 70px;
}
.slick-list, .slick-track, .slick-slide, .slick-slide img{
    max-height: 180px!important;
}
.slick-arrow{
    display: none!important;
}
